
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmAudioPlayer from '@/components/shared/TmAudioPlayer.vue'
import TmCountdown from '@/components/shared/TmCountdown.vue'
import TmPhoneSelect from '@/components/shared/TmPhoneSelect.vue'
import TmPositionsSwitch from '@/components/shared/TmPositionsSwitch.vue'
import TmPreviewPhone from '@/components/shared/TmPreviewPhone.vue'
import TmPriority from '@/components/shared/TmPriority.vue'
import { priorityVariant } from '@/definitions/_general/_data/priorityVariant'
import TmSystemInfo from '@/components/shared/TmSystemInfo.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmUserPreview from '@/components/shared/TmUserPreview.vue'
import TmWatchTutorial from '@/components/shared/TmWatchTutorial.vue'
import TmWeekSchedule from '@/components/shared/TmWeekSchedule.vue'
import { weekScheduleDefault } from '@/definitions/_general/_data/general'

export default defineComponent({
  components: {
    TmWeekSchedule,
    TmWatchTutorial,
    TmButton,
    TmUserPreview,
    TmSystemInfo,
    TmPriority,
    TmPreviewPhone,
    TmPositionsSwitch,
    TmPhoneSelect,
    TmCountdown,
    TmAudioPlayer,
    TmFormLine,
  },
  setup() {
    const numbersOptions = ref([
      '(603) 555-0121',
      '(603) 555-0122',
      '(603) 555-0123',
      '(603) 555-0124',
      '(603) 555-0125',
    ])
    const numbers = ref([])
    const priorities = priorityVariant
    const systemInfoItems = [
      {
        label: 'Label',
        text: 'Text',
      },
      {
        label: 'Label 2',
        text: 'Text 2',
      },
      {
        label: 'Label 3',
        text: 'Text 3',
      },
      {
        label: 'Label 4',
        text: 'Text 4',
      },
    ]

    return {
      systemInfoItems,
      priorities,
      weekScheduleDefault,
      numbers,
      numbersOptions,
    }
  },
})
