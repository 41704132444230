
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  name: 'TmPreviewPhone',
  components: { TmButton },
  setup() {
    const value = ref('')

    return {
      value,
    }
  },
})
